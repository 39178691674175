import React, { useEffect, useState } from "react";
import FloatingLabel from "../../common/components/inputs/FloatingLabel";
import Button from "../../common/components/Button/Button";
import Utils from "../../utility";
import {
  getInvoiceList,
  updateCardDetails,
  updateUserDetails
} from "../../services/userService";
import ViewAll from "../popup/viewAll";
import CancelPopup from "../../common/components/popup/cancelPopup";
import Dropdown from "../../common/components/dropdown";
import { Country, State, City } from "country-state-city";
import InvoicePopup from "../popup/invoicePopup";
import PricingPopup from "./pricing";
import BuyMoreTokenPopup from "../welcome/project/buyMoreTokenPop";
import { useDispatch } from "react-redux";
import {
  hideLoader,
  showLoader,
  updateUserDetail
} from "../../slices/userSlice";
import UpgradeSubscription from "./upgradeSubscription";
import PopupBeforesubscription from "./popupBeforesubscription";

const numberLengthRegex = new RegExp(/^\d{0,12}$/);
const zipNumberLengthRegex = new RegExp(/^\d{0,12}$/);

const UserDetails = ({ tab }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [showPopup, setShowPopup] = useState(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [selectedInvoiceUrl, setSelectedInvoiceUrl] = useState("");
  const Info = JSON.parse(localStorage.getItem("info"));
  const isPlanPurchased = Info?.subscriptionDetails;
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [dropdownCountry, setDropdownCountry] = useState(false);
  const [dropdownCity, setDropdownCity] = useState(false);
  const [dropdownState, setDropdownState] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const [state, setState] = useState({
    firstName: Info?.profile?.firstName,
    lastName: Info?.profile?.lastName,
    email: userDetails?.email?.includes("@") && userDetails?.email,
    phoneNumber: Info?.profile?.phoneNumber,
    businessType: "",
    company: Info?.company,
    position: Info?.position,
    address: Info?.addressDetails?.address,
    city: Info?.addressDetails?.city,
    state: Info?.addressDetails?.state,
    zipcode: Info?.addressDetails?.zipcode,
    country: Info?.addressDetails?.country
  });
  const [search, setSearch] = useState({
    country: "",
    state: "",
    city: ""
  });

  const [error, setError] = useState("");
  const [isPricingOpen, setIsPricingOpen] = useState(false);
  const [moreToken, setMoreToken] = useState(false);
  const [currentPlanOpen, setCurrentPlanOpen] = useState(false);
  const [isRulesBeforeSubscription, setIsRulesBeforeSubscription] =
    useState(false);
  const handleSwitchCurrentPlan = () => {
    setCurrentPlanOpen(!currentPlanOpen);
  };

  const handlePricingPopup = () => {
    setIsPricingOpen(!isPricingOpen);
  };

  const handlePopupBeforesubscriptionCallback = (data) => {
    if (data === false)
      setIsRulesBeforeSubscription(!isRulesBeforeSubscription);
    if (data === true) {
      setIsRulesBeforeSubscription(false);
      setIsPricingOpen(!isPricingOpen);
    }
  };
  const handlePopupBeforesubscription = () => {
    setIsRulesBeforeSubscription(!isRulesBeforeSubscription);
  };
  const handlChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (name === "company" && value) {
      setError("");
    }
  };

  const onCancelClick = () => {
    setModal(true);
  };

  const updateUserProfile = async () => {
    if (!state.firstName) {
      Utils.failureToastMessage("First name is mandatory");
      return;
    }

    let request = {
      profile: {
        firstName: state.firstName,
        lastName: state.lastName,
        phoneNumber: state.phoneNumber
      },
      businessType: state.company ? "organization" : "individual"
    };

    try {
      dispatch(showLoader());
      let [error, response] = await Utils.parseResponse(
        updateUserDetails(request)
      );

      if (error || !response) {
        Utils.failureToastMessage("Failed to update user profile");
        return;
      }

      dispatch(updateUserDetail(response?.responseData));
      // localStorage.setItem("info", JSON.stringify(response?.responseData));
      Utils.successToastMessage("User profile information updated");
    } catch (error) {
      console.error("Error updating user profile:", error);
      Utils.failureToastMessage(
        "An error occurred while updating the profile. Please try again."
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const updateOrganizationDetails = async () => {
    if (!state.company) {
      setError("Company name is required");
      return;
    }

    let request = {
      ...(state.company
        ? { businessType: "organization" }
        : { businessType: "individual" }),
      company: state.company,
      position: state.position,
      addressDetails: {
        address: state.address,
        city: state.city,
        state: state.state,
        zipcode: state.zipcode,
        country: state.country
      }
    };

    try {
      dispatch(showLoader());
      let [error, response] = await Utils.parseResponse(
        updateUserDetails(request)
      );

      if (error || !response) {
        Utils.failureToastMessage("Failed to update organization details");
        return;
      }

      dispatch(updateUserDetail(response?.responseData));
      // localStorage.setItem("info", JSON.stringify(response?.responseData));
      Utils.successToastMessage("Organization details updated");
    } catch (error) {
      console.error("Error updating organization details:", error);
      Utils.failureToastMessage(
        "An error occurred while updating the organization details. Please try again."
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleViewAllClick = () => {
    setShowPopup("INVOICE_LIST");
  };

  const handlePhoneNumber = (e) => {
    const inputValue = e.target.value;
    if (numberLengthRegex.test(inputValue)) {
      setState((prev) => ({
        ...prev,
        phoneNumber: e.target.value
      }));
    }
  };
  const handleZipCode = (e) => {
    const inputValue = e.target.value;
    if (zipNumberLengthRegex.test(inputValue)) {
      setState((prev) => ({
        ...prev,
        zipcode: e.target.value
      }));
    }
  };
  useEffect(() => {
    setCountryList(Country.getAllCountries());
    if (state?.country) {
      const filterData = Country.getAllCountries()?.filter(
        (value) => value?.name === state?.country
      );
      const filterState = State.getAllStates()?.filter(
        (value) => value?.countryCode === filterData[0]?.isoCode
      );
      setStateList(filterState);

      const filterParticularState = filterState?.filter(
        (value) => value?.name === state?.state
      );

      const filterDataCity = City.getCitiesOfState(
        filterParticularState[0]?.countryCode,
        filterParticularState[0]?.isoCode
      );
      setCityList(filterDataCity);
    }
  }, [state?.state, state?.city, state?.country]);

  const handleSearchCountry = (e) => {
    const searchValue = e.target.value?.trim().toLowerCase();

    setSearch((prev) => ({ ...prev, country: searchValue }));
    if (searchValue?.length > 0) {
      const filterData = Country.getAllCountries().filter((item) =>
        item?.name?.toLowerCase().includes(searchValue)
      );
      setCountryList(filterData);
    } else {
      setCountryList(Country.getAllCountries());
    }
  };

  const handleSearchState = (e) => {
    const searchValue = e.target.value?.trim().toLowerCase();
    const filterData = Country.getAllCountries()?.filter(
      (value) => value?.name === state?.country
    );
    const filterState = State.getAllStates()?.filter(
      (value) => value?.countryCode === filterData[0]?.isoCode
    );
    setSearch((prev) => ({ ...prev, state: searchValue }));
    if (searchValue?.length > 0) {
      const filterData = filterState.filter((item) =>
        item?.name?.toLowerCase().includes(searchValue)
      );
      setStateList(filterData);
    } else {
      setStateList(filterState);
    }
  };

  const handleSearchCity = (e) => {
    const searchValue = e.target.value?.trim().toLowerCase();
    const filterData = Country.getAllCountries()?.filter(
      (value) => value?.name === state?.country
    );
    const filterState = State.getAllStates()?.filter(
      (value) => value?.countryCode === filterData[0]?.isoCode
    );
    const filterParticularState = filterState?.filter(
      (value) => value?.name === state?.state
    );

    const filterDataCity = City.getCitiesOfState(
      filterParticularState[0]?.countryCode,
      filterParticularState[0]?.isoCode
    );

    setSearch((prev) => ({ ...prev, city: searchValue }));
    if (searchValue?.length > 0) {
      const filterData = filterDataCity.filter((item) =>
        item?.name?.toLowerCase().includes(searchValue)
      );
      setCityList(filterData);
    } else {
      setCityList(filterDataCity);
    }
  };

  const handleDeselect = () => {
    setSearch((prev) => ({ ...prev, city: "", state: "", country: "" }));
  };

  const handleInvoiceClick = (inVoiceId, invoiceURL) => {
    setSelectedInvoiceId(inVoiceId);
    setSelectedInvoiceUrl(invoiceURL);
    setShowPopup("INVOICE_DETAILS");
  };

  const [planName, planPeriod, planAmount] = Utils.getSubscriptionPlanInfo(
    isPlanPurchased?.priceId
  );
  const [upcomingPlanName, upcomingPlanPeriod, upcomingPlanAmount] =
    Utils.getSubscriptionPlanInfo(isPlanPurchased?.upgradedPlanId);
  const getUserInvoiceList = async () => {
    const limit = 5;

    const requestData = {
      page: 1,
      limit: limit
    };
    try {
      dispatch(showLoader());
      let [error, response] = await Utils.parseResponse(
        getInvoiceList(requestData)
      );

      if (error || !response) {
        return;
      }

      if (response?.responseData?.length > 0) {
        const invoicesList = response.responseData.map((data) => ({
          createdAt: Utils.formatDateInStringForm(data?.createdAt) || "N/A",
          invoiceId: data?.invoiceId || "N/A",
          price: data?.price || "N/A",
          invoiceUrl: data?.invoiceUrl || "N/A"
        }));
        setInvoiceList(invoicesList);
      } else {
        setInvoiceList([]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    if (!state.address || state.address.trim() === "") {
      setState((prevState) => ({
        ...prevState,
        country: "",
        state: "",
        city: "",
        zipcode: ""
      }));
    }
  }, [state.address]);
  useEffect(() => {
    getUserInvoiceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FUNCTION TO UPDATE THE CARD DETAILS
  const updateUserCardDetails = async () => {
    try {
      dispatch(showLoader());
      const [error, response] = await Utils.parseResponse(updateCardDetails());
      if (error) {
        Utils.failureToastMessage(
          "Please subscribe to the plan before updating your card details."
        );
        return;
      } else {
        window.location.href = response?.responseData?.url;
      }
    } catch (error) {
      console.error(error);
      Utils.failureToastMessage(
        "Some error occured while updating the card details of the user"
      );
      return;
    } finally {
      dispatch(hideLoader());
    }
  };

  const isAddressFilled = state.address && state.address.trim() !== "";

  return (
    <>
      {tab === "ACCOUNT" && (
        <div className="mt-14 flex size-full max-w-[98%] justify-between gap-x-5">
          <div className=" flex w-full flex-col rounded-2xl bg-white">
            <span className="pl-5 pt-5 font-InterBold text-ft16-19.36 text-grey-24">
              User Information
            </span>
            <div className="flex w-60per flex-col gap-7 pl-10 pt-8">
              <div className="w-full">
                <FloatingLabel
                  label={"First Name"}
                  name={"firstName"}
                  onBoard={true}
                  isStar={true}
                  isSmall={false}
                  maxLength={50}
                  height={"h-11.2"}
                  width={"w-full"}
                  isSelect={false}
                  value={state.firstName}
                  onChange={handlChange}
                  isWidthFull={true}
                />
              </div>
              <div className="w-full">
                <FloatingLabel
                  label={"Last Name"}
                  name={"lastName"}
                  onBoard={true}
                  isStar={false}
                  isSmall={false}
                  height={"h-11.2"}
                  maxLength={50}
                  width={"w-full"}
                  isSelect={false}
                  value={state.lastName}
                  onChange={handlChange}
                  isWidthFull={true}
                />
              </div>
              <div className="pointer-events-none w-full">
                <FloatingLabel
                  label={"Email Address"}
                  name={"email"}
                  onBoard={true}
                  isStar={false}
                  isSmall={false}
                  height={"h-11.2"}
                  width={"w-full"}
                  isSelect={false}
                  value={state.email}
                  onChange={handlChange}
                  isGreyd={userDetails?.email?.includes("@") && true}
                  isWidthFull={true}
                />
              </div>
              <div className="w-full">
                <FloatingLabel
                  label={"Phone Number"}
                  name={"phoneNumber"}
                  onBoard={true}
                  isStar={false}
                  isSmall={false}
                  height={"h-11.2"}
                  width={"w-full"}
                  isSelect={false}
                  value={state.phoneNumber}
                  onChange={handlePhoneNumber}
                  isWidthFull={true}
                />
              </div>
              <div className="w-full">
                <Button
                  style={
                    "rounded-2xl bg-green-12 text-ft14-21 w-[203px] h-[45px] px-2 items-center font-InterRegular text-white"
                  }
                  label={"UPDATE"}
                  onClick={updateUserProfile}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col  overflow-y-auto rounded-2xl bg-white py-7.5">
            <span className="pl-5 font-InterBold text-ft16-19.36 text-grey-24">
              Organization
            </span>
            <div className="flex w-60per flex-col gap-7 pl-10 pt-8">
              <div className="w-full">
                <FloatingLabel
                  label={"Company"}
                  name={"company"}
                  onBoard={true}
                  isStar={false}
                  isSmall={false}
                  height={"h-11.2"}
                  width={"w-full"}
                  isSelect={false}
                  value={state.company}
                  onChange={handlChange}
                  isWidthFull={true}
                />
                {error && <div style={{ color: "red" }}>{error}</div>}
              </div>
              <div>
                <FloatingLabel
                  label={"Position"}
                  name={"position"}
                  onBoard={true}
                  isStar={false}
                  isSmall={false}
                  height={"h-11.2"}
                  width={"w-full"}
                  isSelect={false}
                  value={state.position}
                  onChange={handlChange}
                  isWidthFull={true}
                />
              </div>
              <div>
                <FloatingLabel
                  label={"Address"}
                  name={"address"}
                  onBoard={true}
                  isStar={false}
                  isSmall={false}
                  height={"h-11.2"}
                  width={"w-full"}
                  isSelect={false}
                  value={state.address}
                  onChange={handlChange}
                  isWidthFull={true}
                />
              </div>
              <div>
                <div className="w-full">
                  <Dropdown
                    dropdown={dropdownCountry}
                    setDropdown={setDropdownCountry}
                    placeholder="Select Country"
                    setValue={(item) => {
                      setState((prev) => ({
                        ...prev,
                        country: item?.name,
                        state: "",
                        city: "",
                        zipcode: ""
                      }));
                      setDropdownState(true);
                    }}
                    value={state.country}
                    list={countryList}
                    title={"Country"}
                    disable={!isAddressFilled}
                    showSearch={true}
                    isGreyd={!isAddressFilled}
                    searchValue={search.country}
                    handleSearch={handleSearchCountry}
                    searchPlaceHolder={"Search"}
                    handleDeselect={handleDeselect}
                  />
                </div>
              </div>
              <div className="flex gap-4">
                <div className="w-1/2">
                  <Dropdown
                    isSelect={false}
                    dropdown={dropdownState}
                    setDropdown={setDropdownState}
                    placeholder="Select State"
                    setValue={(item) => {
                      setState((prev) => ({
                        ...prev,
                        state: item?.name,
                        city: ""
                      }));
                      // setDropdownCity(true);
                    }}
                    value={state.state}
                    list={stateList}
                    disable={!state.country || !isAddressFilled}
                    isGreyd={!state.country || !isAddressFilled}
                    title={"State/Providence"}
                    // disabled={
                    //   (!state.country || !dropdownState) && isAddressFilled
                    // }
                    showSearch={true}
                    searchValue={search.state}
                    handleSearch={handleSearchState}
                    searchPlaceHolder={"Search"}
                    handleDeselect={handleDeselect}
                  />
                </div>
                <div className="w-1/2">
                  <Dropdown
                    isSelect={false}
                    dropdown={dropdownCity}
                    setDropdown={setDropdownCity}
                    placeholder="Select City"
                    setValue={(item) => {
                      setState((prev) => ({ ...prev, city: item?.name }));
                    }}
                    // disable={!isAddressFilled}
                    value={state.city}
                    list={cityList}
                    disable={!state.state || !isAddressFilled}
                    isGreyd={!state.state || !isAddressFilled}
                    title={"City"}
                    showSearch={true}
                    searchValue={search.city}
                    handleSearch={handleSearchCity}
                    searchPlaceHolder={"Search"}
                    handleDeselect={handleDeselect}
                  />
                </div>
              </div>
              <div>
                <FloatingLabel
                  label={"Zipcode"}
                  name={"zipcode"}
                  onBoard={true}
                  isStar={false}
                  isSmall={false}
                  height={"h-11.2"}
                  width={"w-full"}
                  isSelect={false}
                  value={state.zipcode}
                  onChange={handleZipCode}
                  isGreyd={!state?.city}
                  isWidthFull={true}
                  disable={!isAddressFilled}
                  disabled={!state.city || !dropdownCity || !isAddressFilled}
                />
              </div>
              <div>
                <Button
                  style={`rounded-2xl bg-green-12 text-ft14-21 w-[203px] h-[45px] px-2 items-center font-InterRegular text-white ${window.innerHeight <= 800 && "mb-15"}`}
                  label={"UPDATE"}
                  onClick={updateOrganizationDetails}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {tab === "BILLING" && (
        <div className="mt-14 flex justify-start gap-10">
          <div className="flex h-[659px] w-[639px] flex-col rounded-2xl bg-white pb-10  ">
            <span className="pl-5 pt-5 font-InterBold text-ft16-19.36 text-grey-24">
              Current Subscription
            </span>
            <div className="ml-12 mr-6 mt-3 flex  h-30.7 w-[563px] justify-between rounded-xl bg-grey-18">
              <div className="flex flex-col  justify-center py-6 pl-7.5">
                <span className="mb-1 font-InterRegular text-ft14-16.94 font-bold text-grey-12">
                  {planName}
                </span>
                <span className="flex items-center text-ft12-18 font-bold text-grey-12">
                  <p className="mr-1 font-InterRegular text-ft12-16 font-normal text-grey-14">
                    Amount:
                  </p>{" "}
                  {isPlanPurchased ? `$${planAmount}/${planPeriod}` : "N/A"}
                </span>
                <span className="flex items-center text-ft12-18 font-bold text-grey-12">
                  <p className="mr-1 font-InterRegular text-ft12-16 font-normal text-grey-14">
                    {isPlanPurchased
                      ? isPlanPurchased.isCancelled
                        ? "Expiring on:"
                        : "Renewal Date:"
                      : "Plan not purchased"}
                  </p>{" "}
                  {isPlanPurchased
                    ? isPlanPurchased?.type === "month"
                      ? Utils.epochTimeToLocalDateString(
                          isPlanPurchased?.endDate
                        )
                      : Utils.epochTimeToLocalDateString(
                          isPlanPurchased?.endDate
                        )
                    : "N/A"}
                </span>
              </div>
              {isPricingOpen && (
                <PricingPopup handlePricingPopup={handlePricingPopup} />
              )}

              {isRulesBeforeSubscription && (
                <PopupBeforesubscription
                  handlePopupBeforesubscriptionCallback={
                    handlePopupBeforesubscriptionCallback
                  }
                />
              )}

              {moreToken && (
                <BuyMoreTokenPopup
                  heading={"Cancel More token"}
                  content={"More token"}
                  buttonText={"Cancel Token"}
                  functionCall={() => setMoreToken(false)}
                  closeModal={() => setMoreToken(false)}
                  isFullScreen={true}
                />
              )}
              {currentPlanOpen && (
                <UpgradeSubscription
                  currentPlanOpen={currentPlanOpen}
                  handleSwitchCurrentPlan={handleSwitchCurrentPlan}
                />
              )}
              <div className="my-auto flex justify-between gap-1.5  pr-7.5">
                <button
                  disabled={isPlanPurchased ? false : true}
                  onClick={() => setMoreToken(true)}
                  className={`flex h-8.7 w-[95px] ${isPlanPurchased ? "opacity-100" : "opacity-50"} items-center justify-center rounded-xl bg-green-12 p-2 font-InterRegular text-ft10-16 text-white`}
                >
                  BUY TOKENS
                </button>

                <button
                  onClick={handlePopupBeforesubscription}
                  className={` ${isPlanPurchased?.isCancelled || (isPlanPurchased && isPlanPurchased.subscription === "year") || isPlanPurchased?.upgradedPlanId ? "hidden" : "flex"} h-8.7 w-[95px] items-center justify-center rounded-xl bg-green-12 p-2 font-InterRegular text-ft10-16 text-white opacity-100`}
                >
                  {isPlanPurchased?.isCancelled
                    ? null
                    : isPlanPurchased && !isPlanPurchased?.upgradedPlanId
                      ? "UPGRADE"
                      : "BUY PLAN"}
                </button>
                <button
                  disabled={isPlanPurchased?.isCancelled ? true : false}
                  onClick={
                    isPlanPurchased &&
                    !isPlanPurchased?.isCancelled &&
                    onCancelClick()
                  }
                  className={`h-8.7 w-[95px] ${isPlanPurchased ? "opacity-100" : "opacity-50"} rounded-xl border border-green-12 p-2 font-InterRegular text-ft10-16   text-green-12`}
                >
                  CANCEL
                </button>
              </div>
            </div>

            {isPlanPurchased?.upgradedPlanId &&
              isPlanPurchased?.upgradedPlanId !== isPlanPurchased?.priceId && (
                <span className="pl-5 pt-5 font-InterBold text-ft16-19.36 text-grey-24">
                  Next Subscription
                </span>
              )}
            {isPlanPurchased?.upgradedPlanId &&
              isPlanPurchased?.upgradedPlanId !== isPlanPurchased?.priceId && (
                <div className="ml-12 mr-6 mt-3 flex  h-30.7 w-[563px] justify-between rounded-xl bg-grey-18">
                  <div className="flex flex-col  justify-center py-6 pl-7.5">
                    <span className="mb-1 font-InterRegular text-ft14-16.94 font-bold text-grey-12">
                      {upcomingPlanName}
                    </span>
                    <span className="flex items-center text-ft12-18 font-bold text-grey-12">
                      <p className="mr-1 font-InterRegular text-ft12-16 font-normal text-grey-14">
                        Amount:
                      </p>{" "}
                      {isPlanPurchased
                        ? `$${upcomingPlanAmount}/${upcomingPlanPeriod}`
                        : "N/A"}
                    </span>
                    <span className="flex items-center text-ft12-18 font-bold text-grey-12">
                      <p className="mr-1 font-InterRegular text-ft12-16 font-normal text-grey-14">
                        {isPlanPurchased
                          ? isPlanPurchased.isCancelled
                            ? "Expiring on:"
                            : "Start Date:"
                          : "Plan not purchased"}
                      </p>{" "}
                      {isPlanPurchased
                        ? isPlanPurchased?.type === "month"
                          ? Utils.epochTimeToLocalDateString(
                              isPlanPurchased?.endDate
                            )
                          : Utils.epochTimeToLocalDateString(
                              isPlanPurchased?.endDate
                            )
                        : "N/A"}
                    </span>
                  </div>

                  <div className="my-auto flex justify-between gap-1.5  pr-7.5">
                    <button
                      onClick={handlePopupBeforesubscription}
                      className={` ${isPlanPurchased?.isCancelled || (isPlanPurchased && isPlanPurchased.subscription === "year") ? "hidden" : "flex"} h-8.7 w-[95px] items-center justify-center rounded-xl bg-green-12 p-2 font-InterRegular text-ft10-16 text-white opacity-100`}
                    >
                      {isPlanPurchased?.isCancelled
                        ? null
                        : isPlanPurchased
                          ? "UPGRADE"
                          : "BUY PLAN"}
                    </button>
                    <button
                      onClick={handleSwitchCurrentPlan}
                      className={`h-8.7 w-auto rounded-xl border border-green-12 p-2 font-InterRegular text-ft10-16   text-green-12`}
                    >
                      SWITCH TO CURRENT PLAN
                    </button>
                  </div>
                </div>
              )}
            <span className="pl-10 pt-5 font-InterBold text-ft16-19.36 text-grey-12">
              Invoices
            </span>
            {invoiceList?.length > 0 ? (
              <div>
                <div className="overflow-y-auto">
                  {invoiceList?.map((invoice, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        invoice?.invoiceId !== "N/A" &&
                        handleInvoiceClick(
                          invoice?.invoiceId,
                          invoice.invoiceUrl
                        )
                      }
                      className="ml-20 mr-36 mt-8 flex w-[396px] cursor-pointer justify-between  overflow-y-auto"
                    >
                      <div className="flex flex-col">
                        <span className="font-InterBold text-ft14-16.94 text-grey-12">
                          {invoice?.createdAt}
                        </span>
                        <span className="text-ft12-18 text-grey-12">
                          {invoice?.invoiceId}
                        </span>
                      </div>
                      <div className="flex items-center gap-3 ">
                        <span className="font-InterBold text-ft12-16 text-grey-14">
                          ${(invoice?.price / 100).toFixed(2) || "N/A"}
                        </span>
                        {invoice.invoiceId !== "N/A" && (
                          <span className="flex font-InterBold text-ft12-18 text-grey-12">
                            <img src={"/images/pdf-icon.svg"} alt="pdf" /> PDF
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="mt-5 flex justify-center">
                  <button
                    onClick={handleViewAllClick}
                    className="h-8.7 w-[110px] rounded-xl border border-green-12 px-5 py-2 text-ft10-16  text-green-12"
                  >
                    VIEW ALL
                  </button>
                </div>
              </div>
            ) : (
              <div className=" mt-48 flex justify-center font-InterBold text-ft30-36 text-black-0">
                No Invoices
              </div>
            )}
          </div>
          <div className="flex h-50 w-[679.5px] flex-col rounded-2xl bg-white ">
            <span className="pl-5 pt-5 font-InterBold text-ft16-19.36 text-grey-24">
              Billing Information
            </span>
            <div className="mx-auto my-7 flex w-85per justify-between rounded-xl">
              <div className="flex flex-col py-6">
                <span className="font-InterBold text-ft14-16.94  text-grey-12">
                  Payment method
                </span>
              </div>
              <div className="my-auto flex justify-between gap-2">
                <button
                  onClick={updateUserCardDetails}
                  className="bg-gradient-black flex h-8.7   w-[139px] items-center justify-center rounded-xl px-6 py-2 font-InterRegular text-ft10-16 text-white"
                >
                  UPDATE CARD
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPopup === "INVOICE_LIST" ? (
        <ViewAll
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          handleInvoiceClick={handleInvoiceClick}
          selectedInvoiceId={selectedInvoiceId}
        />
      ) : (
        showPopup === "INVOICE_DETAILS" && (
          <InvoicePopup
            selectedInvoiceId={selectedInvoiceId}
            selectedInvoiceUrl={selectedInvoiceUrl}
            onClose={() => setShowPopup(null)}
            setShowPopup={setShowPopup}
          />
        )
      )}

      {modal && (
        <CancelPopup
          heading={"Cancel Account"}
          content={
            " Are you sure you want to cancel your account? Here is what you will miss out on."
          }
          buttonText={"Cancel Account"}
          functionCall={() => setModal(false)}
          closeModal={() => setModal(false)}
          isFullScreen={true}
        />
      )}
    </>
  );
};
export default UserDetails;
