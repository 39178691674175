import React, { useState } from "react";
import Button from "../../common/components/Button/Button";
import PricingPopup from "./pricing";
const PopupBeforesubscription = ({ handlePopupBeforesubscriptionCallback }) => {
  const [isPricingOpen, setIsPricingOpen] = useState(false);

  const handlePricingPopup = () => {
    setIsPricingOpen(!isPricingOpen);
  };

  const handleButtonClick = (buttonType) => {
    if (buttonType === "openPricing") {
      handlePopupBeforesubscriptionCallback(true);
    } else {
      handlePopupBeforesubscriptionCallback(false);
    }
  };

  return (
    <div className="fixed right-0 top-0 z-50 flex size-full items-center justify-center bg-blurDark px-15 backdrop-blur-sm">
      <div className="flex h-80per w-70per cursor-pointer flex-col items-center overflow-auto rounded-15px bg-white p-7 scrollbar-hide">
        <img
          onClick={() => handleButtonClick("closeBeforeSubscriptionpopup")}
          className="ml-auto cursor-pointer"
          src="/images/close-icon.svg"
          alt="Close"
        />
        <h1 className="mt-3 text-center font-InterBold text-ft32-41.6">
          Subscription Terms and Conditions
        </h1>
        <p className="mt-2 h-10 w-[72.2%] whitespace-pre-line text-center font-InterRegular text-ft14-20 text-grey-14">
          Please review the following terms for your <br />
          AdPerfect subscription
        </p>

        <div className="flex space-x-4 p-4">
          <div className="w-1/2 text-left font-InterRegular text-ft14-20 text-grey-14">
            <h2 className="mb-0 font-InterBold text-ft14-20 text-grey-14">
              Monthly Plans:
            </h2>
            <ul className="list-disc space-y-1 pl-12 pr-9">
              <li>Monthly billing occurs on the same date each month.</li>
              <li>
                Cancel anytime - Cancellation takes effect at the end of the
                current billing period.
              </li>
              <li>Price changes will be communicated 30 days in advance.</li>
              <li>
                Generated assets remain yours to keep even after cancellation
                for 12 months.
              </li>
              <li>
                The maximum number of AI image generations per month is limited
                by the number of tokens you have in your account.
              </li>
              <li>Unlimited text-based marketing content generation.</li>
              <li>The plan can be upgraded to annual billing at any time.</li>
              <li>Unused credits roll over to the next month.</li>
            </ul>
          </div>

          <div className="w-1/2 text-left font-InterRegular text-ft14-20 text-grey-14">
            <h2 className="mb-0 font-InterBold text-ft14-20 text-grey-14 ">
              Annual Plans:
            </h2>
            <ul className="list-disc space-y-1 pl-9 pr-12 ">
              <li>Save 20% compared to monthly billing.</li>
              <li>Billed once annually.</li>
              <li>
                A 12-month commitment is required, but you can cancel within the
                first 30 days if you are not satisfied with AdPerfect.
              </li>
              <li>
                Early cancellation is subject to terms outlined in the refund
                policy.
              </li>
              <li>
                Generated assets remain yours to keep even after cancellation
                for 12 months.
              </li>
              <li>
                The maximum number of AI image generation per month is limited
                by the number of tokens you have in your account.
              </li>
              <li>Unlimited text-based marketing content generation.</li>
              <li>Unused credits expire at the end of the billing year.</li>
            </ul>
          </div>
        </div>

        <div className="flex w-full items-center p-0 text-left font-InterRegular text-ft14-20 text-grey-14">
          <p className="pl-5 text-grey-14">
            <span className="mr-1 font-InterBold text-ft14-20 text-grey-14">
              Usage Policy:
            </span>
            Our AI-generated content is provided for commercial use within your
            organization&apos;s marketing efforts. Reselling or redistributing
            generations is not permitted.
          </p>
        </div>
        <br />
        <div className="flex w-full items-center pr-10 text-left font-InterRegular text-ft14-20 text-grey-14">
          <p className="pl-5 text-grey-14 ">
            <span className="mr-1 font-InterBold text-ft14-20 text-grey-14">
              Refund Policy:
            </span>
            Monthly plans can be cancelled at any time. Annual plans may be
            eligible for a partial refund within the first 30 days if
            you&apos;re unsatisfied with the service.
          </p>
        </div>

        <br />
        <br />
        <div className="mb-22.2 flex items-center gap-8">
          <Button
            style={
              "rounded-2xl border-2 border-grey-14   bg-white px-20.8 py-1.7   font-InterBold text-grey-14"
            }
            label={"BACK"}
            onClick={() => handleButtonClick("closeBeforeSubscriptionpopup")}
          />
          <Button
            style={
              "rounded-2xl   bg-green-12 px-20.8 py-1.7   font-InterBold text-white"
            }
            onClick={() => handleButtonClick("openPricing")}
            label={"BUY PLAN"}
          />
        </div>
      </div>
      {isPricingOpen && (
        <PricingPopup handlePricingPopup={handlePricingPopup} />
      )}
    </div>
  );
};
export default PopupBeforesubscription;
